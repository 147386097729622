<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-select
          :options="statusObj"
          v-model="searchParam.statusList"
          @change="$refs.list.search()"
          label="审议状态"
        />
        <v-date-picker
          label="发起时间"
          v-model="proposeTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="请选择开始时间"
          endPlaceholder="请选择结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button
          v-if="scope.row.status == 4"
          text="审议不通过"
          type="text"
          disabled
        />
        <v-button v-else text="审议" type="text" @click="examine(scope.row)" />
      </template>
    </v-list>
    <!-- 审核 -->
    <v-dialog title="审核" v-model="showDialog1" width="35%" @confirm="submit1">
      <el-form label-position="right" label-width="120px">
        <el-form-item label="提议发起人">
          <v-input v-model="form1.proposeUserName" disabled />
        </el-form-item>
        <el-form-item label="提议议题">
          <v-input v-model="form1.proposeTitle" disabled />
        </el-form-item>
        <el-form-item label="提议内容">
          <v-input v-model="form1.proposeContent" type="textarea" disabled />
        </el-form-item>
        <el-form-item label="上传照片">
          <v-upload :limit="9" :imgUrls="form1.proposeAnnexFiles" disabled />
        </el-form-item>
        <el-form-item label="审核">
          <v-select clearable v-model="status" :options="statusObj2" />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  getVoteHallEventListUrl,
  examineVoteHallEventUrl,
  deleteMyProposeEventUrl,
} from "./api.js";
export default {
  name: "proposalConsideration",
  data() {
    return {
      statusObj: [
        {
          value: "4",
          label: "未通过",
        },
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "1,4",
          label: "全部",
        },
      ],
      statusObj2: [
        {
          value: "3",
          label: "审议通过",
        },
        {
          value: "4",
          label: "审议不通过",
        },
      ],
      showDialog1: false,
      searchParam: {
        proposeTimeS: "",
        proposeTimeE: "",
        statusList: "1,4",
      },
      form1: {},
      status: null,
      tableUrl: getVoteHallEventListUrl,
      headers: [
        {
          prop: "proposeUserName",
          label: "提议发起人",
        },
        {
          prop: "proposeTitle",
          label: "提议主题",
        },
        {
          prop: "proposeContent",
          label: "提议内容",
        },
        {
          prop: "proposeTime",
          label: "发起时间",
        },
      ],
    };
  },
  computed: {
    proposeTime: {
      get() {
        return !this.searchParam.proposeTimeS && !this.searchParam.proposeTimeE
          ? []
          : [this.searchParam.proposeTimeS, this.searchParam.proposeTimeE];
      },
      set(val) {
        [this.searchParam.proposeTimeS, this.searchParam.proposeTimeE] =
          val || ["", ""];
      },
    },
  },
  methods: {
    examine(item) {
      this.form1 = item;
      this.status = null;
      this.showDialog1 = true;
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(`${deleteMyProposeEventUrl}?id=${item.id}`)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            }
          });
      });
    },
    submit1() {
      if (!this.status) {
        this.$message("请选择审核状态");
        return;
      }
      let params = {
        id: this.form1.id,
        status: this.status,
      };
      this.$axios.post(`${examineVoteHallEventUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.showDialog1 = false;
          this.$message.success("操作成功！");
          this.$refs.list.search();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
